import React, { useState, useRef } from "react";
import { Button, TextField, Link } from "@hdir/ui";

import "./limitedSearch.scss";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { limitedSearchPropsType } from "./types.d";

import FontAwesomeIconWrapper from "../../../react4xp/shared/FontAwesomeIconWrapper/FontAwesomeIconWrapper";

function LimitedSearch(props: Readonly<limitedSearchPropsType>) {
  const {
    title,
    description,
    image,
    imageAltText,
    searchLabel,
    searchResultLink,
    contentsLinkText,
    filters,
    phrases
  } = props;

  const [error, setError] = useState("");
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();
    const searchText = searchInputRef.current?.value;
    if (!searchText) {
      setError(phrases["search.searchError"]);
      searchInputRef.current?.focus();
      return;
    }
    const encodedValue = encodeURIComponent(searchText);
    let searchUrl = `${searchResultLink}?searchquery=${encodedValue}`;
    if (filters) {
      searchUrl = `${searchUrl}&filter=${filters}`;
    }
    window.location.href = searchUrl;
  };

  return (
    <div className="py-sm tablet:py-md desktop:py-lg">
      <div
        className={`flex nt-flex-col bg-surface-neutral-subtle ${image && "items-stretch tablet:items-center"} px-lg py-xl tablet:flex-row tablet:px-xxxl tablet:py-xxl`}
      >
        {image
          && (
            <div className="flex justify-center w-full tablet:w-1/3">
              <img
                className="w-full tablet:w-4/5 h-auto max-h-60 mr-0 mb-xxl object-contain desktop:mr-md desktop:mb-0"
                src={image}
                alt={imageAltText}
              />
            </div>
          )}
        <form className="search-content break-normal hyphens-auto w-full tablet:w-2/3 tablet:ml-md" onSubmit={(e) => handleSearch(e)}>
          <h2 className="text-neutral text-xxl mb-xs">{title}</h2>
          <p className="text-md mb-lg">{description}</p>
          <div className={`search-bar my-xl mx-0 flex ${error ? "items-center" : "items-end"}`}>
            {
              // TODO: should be replaced by https://designsystem.helsedir.no/0410c9e94/p/246586-search
              // from the designsystem after it gets live.
            }
            <FontAwesomeIconWrapper
              icon={faSearch}
              style={{ color: "var(--design-color-icon-action-default)" }}
              className={`search-icon absolute pl-md z-10 ${error ? "mt-inherit tablet:mt-0" : "pb-sm"}`}
            />
            <TextField
              className="search-input min-w-[65%]"
              placeholder={phrases["search.placeholder"]}
              label={searchLabel}
              name="search-text"
              type="text"
              error={error}
              ref={searchInputRef}
            />
            <Button
              loadingText={phrases["search.ongoing"]}
              size="medium"
              variant="primary"
              onClick={handleSearch}
              className={`${error ? "mt-inherit tablet:mt-xs" : ""}`}
            >
              {phrases["search.search"]}
            </Button>
          </div>
          {contentsLinkText && <Link href={searchResultLink}>{contentsLinkText}</Link>}
        </form>
      </div>
    </div>
  );
}

export default function(props: Readonly<limitedSearchPropsType>) {
  return <LimitedSearch {...props} />;
}
